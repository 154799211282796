<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/userManagement' }">用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <div v-if="!showEdit">
        <div class="title">用户详情</div>
        <div class="detail-container">
          <el-row class="detail-row">
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">用户编号</div>
                <div class="detail-item__val">{{ userDetail.customerId }}</div>
              </div>
            </el-col>
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">注册时间</div>
                <div class="detail-item__val">{{ formatCreateTime }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="detail-row">
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">用户账号</div>
                <div class="detail-item__val">{{ userDetail.account }}</div>
              </div>
            </el-col>
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">用户昵称</div>
                <div class="detail-item__val">
                  {{ userDetail.nickName || "-" }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="detail-row">
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">关联角色</div>
                <div class="detail-item__val">{{ userDetail.roleName }}</div>
              </div>
            </el-col>
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">所属部门</div>
                <div class="detail-item__val">{{ userDetail.deptName }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="detail-row">
            <div class="detail-item">
              <div class="detail-item__title">关联操作仓</div>
              <div>
                {{ userDetail.tenantName || "-" }}
              </div>
            </div>
          </el-row>
          <el-row class="detail-row">
            <div class="detail-item">
              <div class="detail-item__title">关联指令仓</div>
              <div>
                {{ userDetail.instructionName || "-" }}
              </div>
            </div>
          </el-row>
          <el-row class="detail-row">
            <div class="detail-item">
              <div class="detail-item__title">关联微态DNO</div>
              <div>
                {{ userDetail.dno || "-" }}
              </div>
            </div>
          </el-row>
          <el-row class="detail-row">
            <div class="detail-item">
              <div class="detail-item__title">是否开启智能客服</div>
              <div>
                {{ userDetail.isOpenIntelligentCustomerService==='1'?'是':'否' }}
              </div>
            </div>
          </el-row>
          <el-row class="detail-row">
            <div class="detail-item">
              <div class="detail-item__title">是否开启盘前共识</div>
              <div>
                {{ userDetail.isOpenPreConsensus==='1'?'是':'否' }}
              </div>
            </div>
          </el-row>
        </div>

        <div class="btn-group">
          <div class="btn edit" @click="edit">编辑</div>
          <div class="btn reset" @click="resetPwd">重置密码</div>
          <div class="btn unlock" @click="unLock">用户解锁</div>
          <div class="btn del" @click="delUser">删除</div>
          <div class="btn back" @click="back">返回</div>
        </div>
      </div>

      <div v-else>
        <div class="title">修改用户信息</div>
        <div class="container" style="width: 500px">
          <el-form ref="userForm" class="user-form" :model="userEdit" :rules="rules" label-width="140px" size="small">
            <el-form-item label="用户账号">
              {{ userEdit.account }}
            </el-form-item>
            <el-form-item label="用户昵称">
              <el-input v-model.trim="userEdit.nickName" maxlength="100"></el-input>
            </el-form-item>
            <el-form-item label="关联角色" prop="roleIdList">
              <el-select v-model="userEdit.roleIdList" multiple placeholder="请选择" size="small" style="width: 100%">
                <el-option v-for="item in options" :key="item.roleId" :label="item.roleName" :value="item.roleId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关联操作仓" style="width: 800px">
              <el-checkbox-group v-model="userEdit.relatedWarehouse">
                <el-checkbox v-for="item in warehouseList" :key="item.tenantId" :label="item.tenantName"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="关联指令仓" style="width: 800px">
              <el-checkbox-group v-model="userEdit.instructionWarehouse">
                <el-checkbox v-for="item in instructionList" :key="item.instructionId" :label="item.instructionName"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="关联微态DNO (可选)">
              <div style="width: 450px">
                <el-input v-model.trim="userEdit.dno" style="width: 280px"></el-input>
                <el-button style="margin: 0 15px" @click="checkDNO">校验</el-button>
                <span class="dno-state">{{ DNOState }}</span>
              </div>
            </el-form-item>
            <el-form-item label="是否开启智能客服">
              <el-radio-group v-model="userEdit.isOpenIntelligentCustomerService">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'0'">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否开启盘前共识">
              <el-radio-group v-model="userEdit.isOpenPreConsensus">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'0'">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>

        <el-dialog v-model="dialogFormVisible" title="用户信息" :width="'500px'">
          <el-form :model="form" class="user-info-form">
            <el-form-item label="用户名" :label-width="formLabelWidth">
              {{ userInfo.userName }}
            </el-form-item>
            <el-form-item label="userId" :label-width="formLabelWidth">
              {{ userInfo.userId }}
            </el-form-item>
            <el-form-item label="DNO" :label-width="formLabelWidth">
              {{ userInfo.dno }}
            </el-form-item>
          </el-form>
          <template #footer>
            <div class="dialog-footer" style="text-align: center">
              <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
              <el-button type="primary" @click="confirmDNO" size="small">确认</el-button>
              <el-button class="btn re-check" @click="reCheck" size="small">重新校验</el-button>
            </div>
          </template>
        </el-dialog>

        <div class="btn-group">
          <div class="btn confirm" @click="confirm">确认修改</div>
          <div class="btn back" @click="backLast">返回</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  getCurrentInstance,
  ref,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { formatterTime } from "../../utils/format";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  setup() {
    const userForm = ref();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      showEdit: false,
      options: [],
      warehouseList: [],
      instructionList: [],
      userDetail: {},
      userEdit: {
        account: "",
        nickName: "",
        roleIdList: [],
        relatedWarehouse: [],
        instructionWarehouse: [],
        dno: "",
        isOpenIntelligentCustomerService: "0",
        isOpenPreConsensus: "0"
      },

      formatCreateTime: "",
      rules: {
        roleIdList: [
          { required: true, message: "请选择关联角色", trigger: "change" },
        ],
        relatedWarehouse: [
          {
            type: "array",
            required: true,
            message: "请选择关联操作仓",
            trigger: "change",
          },
        ],
      },
      dialogFormVisible: false,
      formLabelWidth: 120,
      DNOState: "",
      userInfo: {},
    });

    watch(
      () => state.userEdit.dno,
      (DNO, preDNO) => {
        if (DNO != preDNO) {
          //  state.userEdit.DNO = '';
          state.DNOState = "";
        }
      }
    );

    onMounted(() => {
      let data = {
        customerId: route.query.id,
      };
      proxy
        .$post("/cts/back/customer/detail", data)
        .then((res) => {
          state.userDetail = res.data;
          state.formatCreateTime = formatterTime(state.userDetail.createTime);
        })
        .catch((err) => { });

      getRoleList();
      getWarehouseList();
      getInstructionList();
    });

    const getRoleList = () => {
      proxy
        .$get("/cts/back/role/allRoleList")
        .then((res) => {
          if (res.code === "200") {
            state.options = res.data;
          }
        })
        .catch((err) => { });
    };

    const getWarehouseList = () => {
      proxy
        .$get("/cts/back/tenantManage/allTenantList")
        .then((res) => {
          if (res.code === "200") {
            state.warehouseList = res.data;
          }
        })
        .catch((err) => { });
    };
    const getInstructionList = () => {
      proxy
        .$get("/cts/back/instruction/allInstructionList")
        .then((res) => {
          if (res.resultState === "200") {
            state.instructionList = res.data;
            console.log(state.instructionList);
          }
        })
        .catch((err) => { });
    };
    const edit = () => {
      state.showEdit = true;
      state.userEdit = JSON.parse(JSON.stringify(state.userDetail));
      console.log(state.userEdit);
      let roleNameList = state.userEdit.roleName
        ? state.userEdit.roleName.split("/")
        : [];
      state.userEdit.roleIdList = [];
      state.options.forEach((item) => {
        if (roleNameList.indexOf(item.roleName) > -1) {
          state.userEdit.roleIdList.push(item.roleId);
        }
      });
      state.userEdit.relatedWarehouse = state.userEdit.tenantName
        ? state.userEdit.tenantName.split("/")
        : [];
      state.userEdit.instructionWarehouse = state.userEdit.instructionName
        ? state.userEdit.instructionName.split("/")
        : [];
    };

    const backLast = () => {
      state.showEdit = false;
    };

    const back = () => {
      router.push({ path: "userManagement" });
    };

    const delUser = () => {
      let params = {
        customerId: route.query.id,
      };
      proxy
        .$del("/cts/back/customer/delete", { data: params })
        .then((res) => {
          if (res.code === "200") {
            ElMessage({
              message: `${res.msg}`,
              type: "success",
            });
            router.push({ path: "userManagement" });
          }
        })
        .catch((err) => { });
    };

    const checkDNO = () => {
      if (!state.userEdit.dno) {
        ElMessageBox.alert("请先填写微态DNO", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      proxy
        .$get(
          `/cts/back/customer/bindMicroStateAccount?dNo=${state.userEdit.dno}`
        )
        .then((res) => {
          state.hasChecked = true;
          if (res.resultState == 200) {
            state.dialogFormVisible = true;
            state.userInfo = res.data;
          }
        })
        .catch((err) => { });
    };

    const confirmDNO = () => {
      state.DNOState = "校验成功";
      state.dialogFormVisible = false;
    };

    const reCheck = () => {
      state.userEdit.dno = "";
      state.DNOState = "";
      state.dialogFormVisible = false;
    };

    const unLock = () => {
      proxy
        .$get(
          `/cts/back/customer/unlockUser?account=${state.userDetail.account}`
        )
        .then((res) => {
          if (res.resultState == 200) {
            ElMessage({
              message: `${res.msg}`,
              type: "success",
            });
          }
        })
        .catch((err) => { });
    };

    const resetPwd = () => {
      let params = {
        customerId: route.query.id,
      };
      proxy
        .$post("/cts/back/customer/resetPwd", params)
        .then((res) => {
          if (res.code === "200") {
            ElMessage({
              message: `${res.msg}`,
              type: "success",
            });
          }
        })
        .catch((err) => { });
    };

    const confirm = () => {
      //如果关联微态DNO有值，则需要先校验；没有的话，则不需要
      if (state.userEdit.dno && !state.DNOState) {
        ElMessageBox.alert("请校验关联微态DNO", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      let tenantId = [];
      state.warehouseList.forEach((item) => {
        if (state.userEdit.relatedWarehouse.indexOf(item.tenantName) > -1) {
          tenantId.push(item.tenantId);
        }
      });
      let instructionId = [];
      state.instructionList.forEach((item) => {
        if (
          state.userEdit.instructionWarehouse.indexOf(item.instructionName) > -1
        ) {
          instructionId.push(item.instructionId);
        }
      });

      let data = {
        customerId: state.userEdit.customerId,
        customerName: "",
        dno: state.userEdit.dno || "",
        microStateUserId: state.userInfo.userId || "",
        microStateUsername: state.userInfo.userName || "",
        nickName: state.userEdit.nickName,
        phone: "",
        roleId: state.userEdit.roleIdList.toString(),
        sex: "",
        tenantId: tenantId.toString(),
        instructionId: instructionId.toString(),
        isOpenIntelligentCustomerService: state.userEdit.isOpenIntelligentCustomerService,
        isOpenPreConsensus: state.userEdit.isOpenPreConsensus
      };

      userForm.value.validate((valid) => {
        if (valid) {
          proxy
            .$put("/cts/back/customer/modify", data)
            .then((res) => {
              if (res.code === "200") {
                ElMessage({
                  message: `${res.msg}`,
                  type: "success",
                });

                state.userDetail = JSON.parse(JSON.stringify(state.userEdit));
                state.userDetail.tenantName =
                  state.userEdit.relatedWarehouse.join("/"); //操作仓名称
                state.userDetail.instructionName =
                  state.userEdit.instructionWarehouse.join("/"); //操作仓名称
                let roleNameList = [];
                state.options.forEach((item) => {
                  if (state.userEdit.roleIdList.indexOf(item.roleId) > -1) {
                    roleNameList.push(item.roleName);
                  }
                });
                state.userDetail.roleName = roleNameList.join("/"); //角色名称

                state.userDetail.dno = state.userEdit.dno; //微态DNO
                state.userDetail.isOpenIntelligentCustomerService = state.userEdit.isOpenIntelligentCustomerService; //微态DNO
                state.userDetail.isOpenPreConsensus = state.userEdit.isOpenPreConsensus; //微态DNO

                state.showEdit = false;
              }
            })
            .catch((err) => { });
        }
      });
    };

    return {
      ...toRefs(state),
      edit,
      backLast,
      back,
      resetPwd,
      unLock,
      delUser,
      confirm,
      userForm,
      checkDNO,
      confirmDNO,
      reCheck,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}
.detail-container {
  padding: 30px;
}
.detail-row {
  height: 50px;
}
.detail-item {
  display: flex;
  align-items: baseline;
  &__title {
    width: 120px;
  }
  &__val {
    width: 800px;
    word-break: break-all;
  }
}
.btn-group {
  display: flex;
  padding: 30px;
  .btn {
    width: 100px;
    text-align: center;
    padding: 6px 0;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    &.edit {
      background: rgb(56, 162, 138);
      color: #fff;
    }
    &.reset {
      background: rgb(241, 222, 146);
      margin: 0 25px;
    }
    &.unlock {
      background: #409eff;
      color: #fff;
      margin-right: 25px;
    }
    &.del {
      background: rgb(255, 127, 127);
      color: #fff;
    }
    &.back {
      border: 1px solid #ddd;
      margin: 0 25px;
    }
    &.confirm {
      background: rgb(56, 162, 138);
      color: #fff;
    }
  }
}
.container {
  padding: 20px;
  &__item {
    display: flex;
    align-items: center;
    &.item {
      margin-top: 15px;
    }
  }
  &__title {
    width: 100px;
  }
  &__input {
    width: 280px;
  }
  .dno-state {
    color: #38a28a;
    font-weight: bold;
  }
  .re-check {
    background: #38a28a;
    color: #fff;
  }
  .user-info-form ::v-deep .el-form-item {
    margin-bottom: 0;
  }
}
</style>
